<template>
    <div class="product-family-all page" v-if="allProductFamilies.length > 0">
        <div
            class="product-family-text-block__item" :class="productFamily.css_class"
            v-for="productFamily in allProductFamilies" :key="productFamily.id"            
        >
            <ProductFamilyTextBlock  
                :currentProdFamily="productFamily" 
                :showButtons="true" 
                :showDesc="false"
                :needLogo="false"
            />
        </div>  
        <PageLoading />       
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'
import ProductFamilyTextBlock from '@/components/pages/productFamily/ProductFamilyTextBlock.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    name: 'ProductFamilyAll',
    mixins: [apiH,viewMI],
    data(){
        return{
            allProductFamilies: [],
            // loadCountNeed: 1,
            // currentPageLoadNum: 0      
        }
    },
    components:{
        ProductFamilyTextBlock,
        PageLoading
    },
    methods:{
        getProductsFamilies(){
            let _this = this

            _this.get('product_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1']                        
                        ]                       
                    }
                },
                function(resp){                                        
                    _this.allProductFamilies = resp.data.product_category

                    //increase the page load num 
                    // _this.currentPageLoadNum += 1
                    
                }, function(err){
                    console.log(err);
                    _this.allProductFamilies = []
                }
            )
        }       
    },
    created(){
        this.getProductsFamilies()
    },
    mounted(){
        this.setMetaTags({
            title: 'Soproni - Termékek', 
            desc: null, 
            keywords: null, 
            ogType: 'website', 
            ogTitle: 'Soproni - Termékek',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:null
        })
    }
}
</script>